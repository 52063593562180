import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatRequest } from ".";
import { IChatListQuery } from "../interfaces/formsInterface/chatListQuery";
import { getToken } from "../utils/Token";
import axios from "axios";

const config = {
    headers: {
        Authorization: "",
        "Content-Type": "application/json",
    },
};

export const fetchChatListing = createAsyncThunk("Chat/chatlisting",async (userData: IChatListQuery) =>{
    try{
        config["headers"]["Authorization"] = getToken();
        const response: unknown | any = await chatRequest.post('/v1/api/chatlisting', userData, config);
        return response.data;
    }catch(err:unknown | any) {
        return `Error fetching Chat Listing ${err.message}`;
    }
});

export const fetchChatOfUser = async (
    loggedInUserId: string,
    partnerUserId: string,
    clientId: string | null,
    roomId: string,
    chatType: string
) => {
    config["headers"]["Authorization"] = getToken();
    const reqBody = {
        loggedInUserId,
        partnerUserId,
        clientId,
        roomId,
        chatType
    };

    const response = await chatRequest.post(
        "/v1/api/chats",
        reqBody,
        config
    );

    return response?.data;
};

export const fetchCitiesStatesFromSpaces = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_CDN_BASE_URL}/us-canada-cities-states/us_canada_citites_states_4_0.json`, { timeout: 3000 });
        return response.data;
    } catch (err) {
        console.error("Error fetching cities/states data: ", err);
        return null;
    }

};