import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { LayoutSettingsState } from "../../interfaces/slicesInterface/layoutSettings";
import { RootState } from "../../app/store";
import { saveLayoutSettings, getLayoutSettings } from "../../services/layoutSettings";

const initialState: LayoutSettingsState = {
    dashboardSettings: {},
    isLayoutSettingsSaved: false,
    loading: false,
    error: "",
};

const layoutSettingsSlice = createSlice({
    name: "layoutSettings",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveLayoutSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveLayoutSettings.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.isLayoutSettingsSaved = action.payload.success;
                state.dashboardSettings = action.payload.data;
                state.error = "";
            })
            .addCase(saveLayoutSettings.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload?.error || "Something went wrong";
                toast.error(state.error);
            })
            .addCase(getLayoutSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(getLayoutSettings.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.dashboardSettings = action.payload.settings;
                state.error = "";
            })
            .addCase(getLayoutSettings.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload?.error || "Something went wrong";
                toast.error(state.error);
            });
    },
});

export const layoutSettingsSelector = (state: RootState) => state.layoutSettings;
export default layoutSettingsSlice.reducer;
