import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PersonalizationState } from "../../interfaces/slicesInterface/personalization";
import { RootState } from "../../app/store";
import {
  getPersonalizationSettings,
  savePersonalizationSettings,
} from "../../services/personalization";
import toast from "react-hot-toast";

const initialState: PersonalizationState = {
  personalization: {
    favoriteRooms: [],
    isFavorite: false,
    dashboardView: {
      view:"gallery",
      sortConfig:{
        column:"",
        order:null,
      }
    },
  },
  loading: false,
  success: false,
  error: "",
};

const personalizationSlice = createSlice({
  name: "personalization",
  initialState,
  reducers: {
    setFavoriteRooms: (state, action: PayloadAction<any>) => {
      state.personalization.favoriteRooms = action.payload.favoriteRooms;
      state.personalization.isFavorite = action.payload.isFavorite;
    },
    setFavoriteState: (state, action: PayloadAction<any>) => {
      state.personalization.isFavorite = action.payload.isFavorite;
    },
    setSortConfig(state, action: PayloadAction<any>) {
      state.personalization.dashboardView.sortConfig.column = action.payload.column;
      state.personalization.dashboardView.sortConfig.order = action.payload.order;
    },
    setDashboardView(state, action: PayloadAction<any>) {
      state.personalization.dashboardView.view = action.payload.view;
    }
  },
  extraReducers(builder) {
    builder.addCase(getPersonalizationSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getPersonalizationSettings.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.loading = false;
          state.personalization = action.payload.personalization || initialState.personalization;
          state.success = true;
        } else {
          state.loading = false;
          state.success = false;
          state.error = action.payload.message;
        }
      }
    );
    builder.addCase(
      getPersonalizationSettings.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      }
    );
    builder.addCase(savePersonalizationSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      savePersonalizationSettings.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.loading = false;
          state.success = true;
          toast.success(action.payload.message);
        }else{
          state.loading = false;
          state.success = false;
          state.error = action.payload.message;
          toast.error(action.payload.message);
        }
      }
    );
    builder.addCase(
      savePersonalizationSettings.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload.message;
      }
    );
  },
});

export const personalizationSelector = (state: RootState) =>
  state.personalization;
export const {
  setFavoriteRooms,
  setFavoriteState,
  setSortConfig,
  setDashboardView,
} = personalizationSlice.actions;
export default personalizationSlice.reducer;
