import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { environmentsProps } from "../../interfaces/slicesInterface/environment";
import { getEnvironments } from "../../services/environment";
import { RootState } from "../../app/store";
const initialState:environmentsProps = {
    environments:{},
    message: "",
    error: "",
    loading:false
  };





  const environmentSlice = createSlice({
    name:"environment",
    initialState,
    reducers:{
        clearEnvironments: (state) => {
              state.environments = {};
            },
     },
    extraReducers:(builder) => {
        builder.addCase(getEnvironments.pending,(state)=>{
            state.loading = false;
        })
        .addCase(getEnvironments.fulfilled,(state,action:PayloadAction<any>) => {
                if(action.payload.success){
                    state.loading = false;
                    let updatedEnvironments:any = {};
                    console.log(action.payload.environments)
                    action.payload.environments.map((item:any) => {
                        return updatedEnvironments[item.variable] = item;
                    });

                    state.environments = updatedEnvironments;
                    state.message ="Successfully fetched the environments"
                }else{
                    state.loading = false;
                    state.message =""
                    state.error = "Cannot Fetch Environments"
                }
        }).addCase(getEnvironments.rejected,(state,action)=>{
            state.error = action.error.message;
            state.loading = false;
        })
    }
  })


  export const environmentSelector = (state:RootState) => state.environment;
  export const {clearEnvironments} = environmentSlice.actions
  export default environmentSlice.reducer