import React, { useEffect, useState } from "react";
import { MeetingRoomScreenProps } from "../../interfaces/componentsInterface/meetingRoom";
import {
  EllipsisVerticalIcon,
  MicrophoneIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import Video from "../Video";
import styles from "./styles.module.css";
import { getInitials } from "../../utils/initials";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  mediaSelector,
  nextPage,
  prevPage,
} from "../../features/media/mediaSlice";
import DefaultImage from '../../assets/images/blur.jpg'
import { environmentSelector } from "../../features/environment/environmentSlice";
import { meetingRoomSelector } from "../../features/meetingRoom/meetingRoomSlice";
import streamService from "../../services/streamService";
import { roomSelector } from "../../features/room/roomSlice";
import { store } from "../../app/store";
import { emojiEventConst, mediaTypeConstant } from "../../utils/constant";
import { getVolumeClasses } from "../../utils/audio";
import { generateThumbnailsUrl } from "../../utils/generateImageURL";
import { peerVolumeSelector } from "../../features/peerVolume/peerVolumeSlice";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { chatSelector } from "../../features/chat/chatSlice";
import { truncateMessage } from "../../utils/truncateMessage";
const streamInstance = new streamService().getInstance();

export default function MeetingRoomScreen(
  props: MeetingRoomScreenProps
): ReturnType<React.FC> {
  const mediaReducer = useAppSelector(mediaSelector);
  const environmentReducer = useAppSelector(environmentSelector);
  const meetingRoomReducer = useAppSelector(meetingRoomSelector);
  const roomReducer = useAppSelector(roomSelector);
  const peerVolume = useAppSelector(peerVolumeSelector);
  const loggedInUser = useAppSelector(userDetailsSelector);
  const dispatch = useAppDispatch();
  const chatReducer = useAppSelector(chatSelector);

  const [imageError, setImageError] = useState(false);

  const spacesConfig = environmentReducer?.environments?.REACT_APP_SPACES_CONFIG?.data?.[0] ?? '';

  const getFlexWidthAndHeight = (peersLength: number, itemNo: number) => {
    let height;
    let width;

    if (peersLength === 7 && itemNo > 3) {
      width = 35;
      height = 32;
    } else if (
      (peersLength === 13 && itemNo > 4) ||
      (peersLength === 14 && itemNo > 8)
    ) {
      width = 28;
      height = 22;
    } else if (peersLength === 1) {
      width = 95;
      height = 100;
    } else if (peersLength === 2 || peersLength === 4 || peersLength === 3) {
      width = 45;
      height = (peersLength === 4 || peersLength === 3) ? 47 : 96;
    } else if (peersLength >= 5 && peersLength <= 9) {
      width = 33;
      height = peersLength <= 6 ? 47 : 32;
    } else if (peersLength <= 16 && peersLength > 9) {
      width = 23;
      height = peersLength <= 12 ? 30 : 22;
    }

    return { height, width };
  };

  const getPagePeerUsersId = () => {
    let currentPageUserIds = mediaReducer.remotePeersId.slice(
      store.getState().media.startIndexForRemotePeer,
      store.getState().media.endIndexForRemotePeer
    );
    return currentPageUserIds;
  };

  const previousPage = () => {
    if (mediaReducer.startIndexForRemotePeer > 0) {
      let previousPageUserIds = getPagePeerUsersId();
      dispatch(
        prevPage({
          perPagePeerNum:
            +environmentReducer?.environments?.REACT_APP_PAGE_SIZE_COLLAB.value,
        })
      );
      let currentPageUserIds = getPagePeerUsersId();
      streamInstance.resumeConsumersForPeer(currentPageUserIds);
      streamInstance.pauseConsumersForPeer(previousPageUserIds);
    }
  };

  const nextPageFun = () => {
    if (
      mediaReducer.endIndexForRemotePeer < mediaReducer.remotePeersId.length
    ) {
      let previousPageUserIds = getPagePeerUsersId();
      dispatch(
        nextPage({
          perPagePeerNum:
            +environmentReducer?.environments?.REACT_APP_PAGE_SIZE_COLLAB.value,
        })
      );
      let currentPageUserIds = getPagePeerUsersId();
      streamInstance.resumeConsumersForPeer(currentPageUserIds);
      streamInstance.pauseConsumersForPeer(previousPageUserIds);
    }
  };

  return (
    <div className="h-full w-full rounded-xl flex justify-center items-center flex-wrap gap-1 ">
      <> {console.log(meetingRoomReducer.screenCollabPeer.peerId)}</>
      {meetingRoomReducer.screenCollabPeer.peerId == "" ? (
        mediaReducer.remotePeersId
          .slice(
            mediaReducer.startIndexForRemotePeer,
            mediaReducer.endIndexForRemotePeer
          )
          .map((userId, index) => {
            let flexObj = getFlexWidthAndHeight(
              mediaReducer.remotePeersId
                .slice(
                  mediaReducer.startIndexForRemotePeer,
                  mediaReducer.endIndexForRemotePeer
                ).length,
              index + 1
            );
            return (
              <div
                style={{
                  width: flexObj.width + "%",
                  height: flexObj.height + "%",
                }}
                className="rounded-xl"
                key={userId}
              >
                {mediaReducer.remotePeers[userId].peerVideoPaused ? (
                  <div
                    style={{ backgroundImage: `url(${mediaReducer.remotePeers[userId].imageUrl ? mediaReducer.remotePeers[userId].imageUrl : DefaultImage} )` }}
                    className=" bg-white bg-cover shadow-xl rounded-xl h-full w-full flex  justify-center items-center gap-1 relative">
                    <div className="w-full h-full rounded-xl flex  justify-center items-center gap-1 backdrop-blur-md ">
                      {mediaReducer.remotePeers[userId].imageUrl && imageError ? (
                        <img
                          className={`inline-block h-28 w-28 rounded-full ring-2 ring-white ${peerVolume &&
                            getVolumeClasses(
                              peerVolume[mediaReducer.remotePeers[userId].peerUserId]
                            )
                            }`}
                          src={mediaReducer.remotePeers[userId].imageUrl}
                          alt="User"
                        />
                      ) : mediaReducer.remotePeers[userId].imageUrl && !imageError ? (
                        <img
                          className={`inline-block h-28 w-28 rounded-full ring-2 ring-white  ${peerVolume &&
                            getVolumeClasses(
                              peerVolume[mediaReducer.remotePeers[userId].peerUserId]
                            )
                            }`}
                          src={generateThumbnailsUrl(
                            mediaReducer.remotePeers[userId]?.imageUrl.split("/").pop(),
                            480,
                            loggedInUser.custId,
                            mediaReducer.remotePeers[userId].peerUserId,
                            spacesConfig
                          )}
                          alt="User"
                          onError={() => setImageError(true)}
                        />
                      ) : (
                        <p
                          className={` w-10 h-10 p-8  bg-blue rounded-full text-white flex justify-center items-center font-semibold border-4 border-white  ring-2  ring-white shadow-xl ${peerVolume &&
                            getVolumeClasses(
                              peerVolume[mediaReducer.remotePeers[userId].peerUserId]
                            )
                            }`}
                        >
                          {getInitials(mediaReducer.remotePeers[userId].peerName)}
                        </p>
                      )}
                      <div title={mediaReducer.remotePeers[userId].peerName} className="text-[.6rem] xl:text-[.9rem] absolute bottom-1 left-1 text-white opacity-70 flex">
                        <p className="bg-gray-500 capitalize p-[.5px] px-[2px] backdrop-blur-sm rounded-md">{truncateMessage(mediaReducer.remotePeers[userId].peerName, 20)}</p>
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForDndPeer === emojiEventConst.DND) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">DND</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.LUNCH) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Lunch</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BRB) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">BRB</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BREAK) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Break</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForMeetingPeer === emojiEventConst.MEETING) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Meeting</span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={` bg-white border-[2px] shadow-xl rounded-xl h-full w-full flex flex-col justify-start items-start gap-1 relative  ${peerVolume && peerVolume[mediaReducer.remotePeers[userId].peerUserId] !== -100
                    ? "border-blue"
                    : "border-transparent"
                    }`}>
                    {streamInstance.peerRemoteStream && streamInstance.peerRemoteStream[userId] && streamInstance.peerRemoteStream[userId].videoStream && (
                      <Video
                        src={
                          streamInstance.peerRemoteStream[userId].videoStream
                        }
                        mediaType={mediaTypeConstant.video}
                        userId={userId}
                      />
                    )}

                    <div title={mediaReducer.remotePeers[userId].peerName} className="lg:text-[.6rem] xl:text-[.9rem] absolute bottom-1 left-1 text-white opacity-70 flex">
                        <p className="bg-gray-500 capitalize p-[.5px] px-[2px] backdrop-blur-sm rounded-md">{truncateMessage(mediaReducer.remotePeers[userId].peerName, 20)}</p>
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForDndPeer === emojiEventConst.DND) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">DND</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.LUNCH) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Lunch</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BRB) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">BRB</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BREAK) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Break</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForMeetingPeer === emojiEventConst.MEETING) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Meeting</span>
                        )}
                    </div>
                  </div>
                )}
              </div>
            );
          })
      ) : (
        <div className=" w-full h-full flex justify-between items-center ">
          <div className=" w-[75%] h-full bg-white rounded-xl">
          {mediaReducer.remotePeersId.length &&
            !mediaReducer.remotePeers[
              meetingRoomReducer.screenCollabPeer.peerId
            ].peerScreenStopped &&
            streamInstance.peerRemoteStream &&
            streamInstance.peerRemoteStream[
            meetingRoomReducer.screenCollabPeer.peerId
            ] && streamInstance.peerRemoteStream[
              meetingRoomReducer.screenCollabPeer.peerId
            ].screenStream && (
                <Video
                  src={
                    streamInstance.peerRemoteStream[
                      meetingRoomReducer.screenCollabPeer.peerId
                    ].screenStream
                  }
                  mediaType={mediaTypeConstant.screen}
                  userId={meetingRoomReducer.screenCollabPeer.peerId}
                />
              )}
            </div>

          <div
            className={`w-[24%] flex flex-col justify-center items-start relative  gap-2    overflow-hidden  bg-gray-50 rounded-xl h-full`}
          >
            {mediaReducer.startIndexForRemotePeer > 0 &&
              mediaReducer.remotePeersId.length && (
                <ChevronUpIcon
                  className=" absolute left-[46%] top-1 w-9 h-9 z-10  text-gray-500 p-2 rounded-full shadow-xl border-[.5px] border-blue mr-5 bg-white cursor-pointer hover:text-gray-900"
                  onClick={() => previousPage()}
                />
              )}

            {mediaReducer.remotePeersId
              .slice(
                mediaReducer.startIndexForRemotePeer,
                mediaReducer.endIndexForRemotePeer
              )
              .map((userId, index) => {
                return !mediaReducer.remotePeers[userId].peerVideoPaused ? (
                  <div className={` bg-white border-[2px] shadow-xl  rounded-xl  w-full flex  justify-center items-center relative min-h-[30%]   ${peerVolume && peerVolume[mediaReducer.remotePeers[userId].peerUserId] !== -100
                    ? "border-blue"
                    : "border-transparent"
                    } `}>
                    {streamInstance.peerRemoteStream &&
                      streamInstance.peerRemoteStream[userId] && streamInstance.peerRemoteStream[userId].videoStream && (
                        <Video
                          src={
                            streamInstance.peerRemoteStream[userId].videoStream
                          }
                          mediaType={mediaTypeConstant.video}
                          userId={userId}
                        />
                      )}
                    <div title={mediaReducer.remotePeers[userId].peerName} className="text-[.6rem] xl:text-[.9rem] absolute bottom-1 left-1 text-white opacity-70 flex">
                        <p className="bg-gray-500 capitalize p-[.5px] px-[2px] backdrop-blur-sm rounded-md">{truncateMessage(mediaReducer.remotePeers[userId].peerName, 20)}</p>
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForDndPeer === emojiEventConst.DND) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">DND</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.LUNCH) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Lunch</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BRB) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">BRB</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BREAK) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Break</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForMeetingPeer === emojiEventConst.MEETING) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Meeting</span>
                        )}
                    </div>
                  </div>
                ) : (
                  <div
                  style={{ backgroundImage: `url(${mediaReducer.remotePeers[userId].imageUrl ? mediaReducer.remotePeers[userId].imageUrl : DefaultImage} )` }}
                  className=" bg-white bg-cover shadow-xl rounded-xl w-full flex  justify-center items-center gap-1 min-h-[30%] relative">
                     <div className="w-full h-full rounded-xl flex  justify-center items-center gap-1 backdrop-blur-md ">
                    {mediaReducer.remotePeers[userId].imageUrl && imageError ? (
                      <img
                        className={`inline-block h-28 w-28 rounded-full ring-2 ring-white ${peerVolume &&
                          getVolumeClasses(
                            peerVolume[mediaReducer.remotePeers[userId].peerUserId]
                          )
                          }`}
                        src={mediaReducer.remotePeers[userId].imageUrl}
                        alt="User"
                      />
                    ) : mediaReducer.remotePeers[userId].imageUrl && !imageError ? (
                      <img
                        className={`inline-block h-28 w-28 rounded-full ring-2 ring-white  ${peerVolume &&
                          getVolumeClasses(
                            peerVolume[mediaReducer.remotePeers[userId].peerUserId]
                          )
                          }`}
                        src={generateThumbnailsUrl(
                          mediaReducer.remotePeers[userId]?.imageUrl.split("/").pop(),
                          480,
                          loggedInUser.custId,
                          mediaReducer.remotePeers[userId].peerUserId,
                          spacesConfig
                        )}
                        alt="User"
                        onError={() => setImageError(true)}
                      />
                    ) : (
                      <p
                        className={` w-10 h-10 p-8  bg-blue rounded-full text-white flex justify-center items-center font-semibold border-4 border-white  ring-2  ring-white shadow-xl ${peerVolume &&
                          getVolumeClasses(
                            peerVolume[mediaReducer.remotePeers[userId].peerUserId]
                          )
                          }`}
                      >
                        {getInitials(mediaReducer.remotePeers[userId].peerName)}
                      </p>
                    )}
                    <div title={mediaReducer.remotePeers[userId].peerName} className="text-[.6rem] xl:text-[.9rem] absolute bottom-1 left-1 text-white opacity-70 flex">
                        <p className="bg-gray-500 capitalize p-[.5px] px-[2px] backdrop-blur-sm rounded-md">{truncateMessage(mediaReducer.remotePeers[userId].peerName, 20)}</p>
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForDndPeer === emojiEventConst.DND) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">DND</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.LUNCH) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Lunch</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BRB) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">BRB</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BREAK) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Break</span>
                        )}
                        {chatReducer.onlineUsers.some(user => user.peerUserId === mediaReducer.remotePeers[userId].peerUserId && user.emojiForMeetingPeer === emojiEventConst.MEETING) && (
                          <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Meeting</span>
                        )}
                    </div>

                    </div>
                  </div>
                );
              })}
            {mediaReducer.endIndexForRemotePeer <
              mediaReducer.remotePeersId.length &&
              mediaReducer.remotePeersId.length && (
                <ChevronDownIcon
                  className=" absolute left-[46%] bottom-1 w-9 h-9 z-10 text-gray-500 p-2 rounded-full shadow-xl border-[.5px] border-blue mr-5 bg-white cursor-pointer hover:text-gray-900"
                  onClick={() => nextPageFun()}
                />
              )}
          </div>
        </div>
      )}
    </div>
  );
}
