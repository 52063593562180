import { createAsyncThunk } from "@reduxjs/toolkit";
import { authRequest } from ".";
import { getToken } from "../utils/Token";
import { PersonalizationParams } from "../interfaces/slicesInterface/personalization";

const config = {
  headers: {
    Authorization: "",
    "Content-Type": "application/json",
  },
};

export const getPersonalizationSettings = createAsyncThunk(
  "personalization/getPersonalizationSettings",
  async () => {
    config["headers"]["Authorization"] = getToken();

    const response = await authRequest.get(
      "/v1/api/personalization/get",
      config
    );
    return response.data;
  }
);

export const saveFavoriteRooms = async (data: any) => {
  config["headers"]["Authorization"] = getToken();
  const response = await authRequest.post("/v1/api/personalization/savefavoriterooms", data, config);
  return response.data;
};

export const saveDashboardView = async (data:any) => {
  config["headers"]["Authorization"] = getToken();
  const response = await authRequest.post("/v1/api/personalization/savedashboardview", data, config);
  return response.data; 
}

export const saveSort = async (data:any) => {
  config["headers"]["Authorization"] = getToken();
  const response = await authRequest.post("/v1/api/personalization/savesort", data, config);
  return response.data; 
}

export const toggleFavoriteState = async (data: any) => {
  config["headers"]["Authorization"] = getToken();
  const response = await authRequest.post("/v1/api/personalization/togglefavoritestate", data, config);
  return response.data;
};

export const savePersonalizationSettings = createAsyncThunk(
  "personalization/savePersonalizationSettings",
  async (data: PersonalizationParams) => {
    // config["headers"]["Authorization"] = getToken();
    // config["headers"]["Content-Type"] = "multipart/form-data";

    const formData = new FormData();
    if (data.image) {
      formData.append("file", data.image);
    }

    formData.append("settings", JSON.stringify(data.settings));
    formData.append("isImageDelete", JSON.stringify(data.isImageToDelete));

    const response = await authRequest.post(
      "/v1/api/personalization/update",
      formData,
        {
          headers: {
            Authorization: getToken(),
            "Content-Type": "multipart/form-data",
          },
        }
    );
    return response.data;
  }
);
