import {
  ChevronLeftIcon,
  UsersIcon,
  BellIcon,
  ChatBubbleLeftRightIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { timeZoneFormat } from "../../utils/datetimeformat/index";

import moment from "moment";

import { NavLayoutProps } from "../../interfaces/componentsInterface/navLayout";
import { useEffect, useState, Fragment, useRef, useLayoutEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon, NoSymbolIcon } from "@heroicons/react/20/solid";
import {
  mediaSelector,
  nextPage,
  prevPage,
} from "../../features/media/mediaSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { roomSelector } from "../../features/room/roomSlice";
import {
  role,
  roomViewModeConst,
  socketConnectiontype,
} from "../../utils/constant";
// import { environmentSelector } from "../../features/environment/environmentSlice";
import { store } from "../../app/store";
import streamService from "../../services/streamService";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import {
  meetingRoomSelector,
  selectCollabScreenPeer,
} from "../../features/meetingRoom/meetingRoomSlice";
import { screenPeerType } from "../../interfaces/slicesInterface/meetingRoom";
import SocketService from "../../services/socket";
import { clearTempRoomUser } from "../../features/tempRoomUser/tempRoomUserSlice";
import { constantMethod } from "../../utils/constantMethod";
import { alertsSelector, setNotificationCounter } from "../../features/alerts/alertsSlice";
import { chatSelector } from "../../features/chat/chatSlice";
// @ts-ignore
import TimerWorker from '../../workers/timerWorker'

const streamInstance = new streamService().getInstance();
const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

export default function RoomNavControls({
  active,
  setActive,
  isAlertActive,
  setIsAlertActive,
  isParticipantActive,
  setIsParticipantActive,
  isSidebarOpen,
  setIsSidebarOpen,
  dndTime,
  dndModalControl
}: NavLayoutProps): ReturnType<React.FC> {
  const location = useLocation();
  const navigate = useNavigate();
  const roomName = location.pathname.split("/").pop();
  const mediaReducer = useAppSelector(mediaSelector);
  const roomDetails = useAppSelector(roomSelector);
  const userDetails = useAppSelector(userDetailsSelector);
  const alerts = useAppSelector(alertsSelector);
  const meetingRoomReducer = useAppSelector(meetingRoomSelector);
  const chatDetails = useAppSelector(chatSelector);
  const dispatch = useAppDispatch();
  const timerRef = useRef<any>(null);
  const workerRef = useRef<Worker | null | any>(null);

  let [time, setTime] = useState(0);
  const [unSeenCount, setunSeenCount] = useState(0)

  const { format } = timeZoneFormat();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);

    setActive(false);
    setIsAlertActive({ val: false, count: 0 });
    setIsParticipantActive({ val: false, count: 0 });

  };
  const handleBack = () => {
    navigate(-1);
    dispatch(clearTempRoomUser());
  };

  const toggleAlertsTab = () => {
    dispatch(setNotificationCounter(0));
    if (isAlertActive.count === 0) {
      setActive(true);
      setIsAlertActive({ val: true, count: 1 });
      setIsParticipantActive({ val: false, count: 0 });
      setIsSidebarOpen(true);
    } else {
      setActive(false);
      setIsAlertActive({ val: false, count: 0 });
      setIsParticipantActive({ val: false, count: 0 });
    }
  };

  const toggleParticipantTab = () => {
    if (isParticipantActive.count === 0) {
      setActive(true);
      setIsParticipantActive({ val: true, count: 1 });
      setIsAlertActive({ val: false, count: 0 });
      setIsSidebarOpen(true);
    } else {
      setActive(false);
      setIsParticipantActive({ val: false, count: 0 });
      setIsAlertActive({ val: false, count: 0 });
    }
  };

  const formatTime = (time: any) => {
    const hours = String(Math.floor(time / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  // page Control

  const getPagePeerUsersId = () => {
    let currentPageUserIds = mediaReducer.remotePeersId.slice(
      store.getState().media.startIndexForRemotePeer,
      store.getState().media.endIndexForRemotePeer
    );
    return currentPageUserIds;
  };

  const previousPage = () => {
    if (mediaReducer.startIndexForRemotePeer > 0) {
      let previousPageUserIds = getPagePeerUsersId();
      dispatch(prevPage({ perPagePeerNum: roomDetails.perPagePeerNum }));
      let currentPageUserIds = getPagePeerUsersId();
      streamInstance.resumeConsumersForPeer(currentPageUserIds);
      streamInstance.pauseConsumersForPeer(previousPageUserIds);
    }
  };

  const nextPageFun = () => {
    if (
      mediaReducer.endIndexForRemotePeer < mediaReducer.remotePeersId.length
    ) {
      let previousPageUserIds = getPagePeerUsersId();
      dispatch(nextPage({ perPagePeerNum: roomDetails.perPagePeerNum }));
      let currentPageUserIds = getPagePeerUsersId();
      streamInstance.resumeConsumersForPeer(currentPageUserIds);
      streamInstance.pauseConsumersForPeer(previousPageUserIds);
    }
  };

  // handling collab screen peer when user selected other peer
  const screenResumeForCollabPeer = async (peer: screenPeerType) => {
    if (meetingRoomReducer.screenCollabPeer.peerId === peer.peerId) return;
    await socketInstance.sendMessage(
      "RESUME_AND_PAUSE_CONSUMERS_SCREEN_FOR_COLLAB_PEER",
      {
        pausePeerId: meetingRoomReducer.screenCollabPeer.peerId,
        resumePeerId: peer.peerId,
      }
    );

    dispatch(
      selectCollabScreenPeer({
        peerId: peer.peerId,
        peerUsername: peer.peerUsername,
      })
    );
  };

  // useEffect(() => {
  //   timerRef.current = setInterval(() => {
  //     setTime((prevTime) => prevTime + 1);
  //   }, 1000);

  //   return () => clearInterval(timerRef.current);
  // });

  useEffect(() => {
    
    workerRef.current = new Worker(new URL('../../workers/timerWorker.js', import.meta.url));

    workerRef.current.onmessage = (event:any) => {
      setTime(event.data);
    };

  
    workerRef.current.postMessage('START');

    return () => {
      // STOP component unmount
      workerRef.current?.postMessage('STOP');
      workerRef.current?.terminate();
    };
  }, []);


  useLayoutEffect(() => {
    const unseenCountForOnlineUsers = chatDetails.chatListing.filter((message) => {
      const isOnline = chatDetails.onlineUsers.some((user) => user.userId === message.userId);
      return isOnline && !message.seen && message.userId !== userDetails.userId && message.userId !== chatDetails.selectedChatUser.userId && message?.sentOrReceived === "received";
    }).length;

    setunSeenCount(unseenCountForOnlineUsers)
  }, [chatDetails.chatListing, userDetails.userId])


  return (
    <>
      <div className="h-[10%] w-full flex justify-between items-center p-2">
        <div className="flex justify-start items-center w-[40%]">
          <ChevronLeftIcon
            className="w-9 h-9  text-gray-500 p-2 rounded-full shadow-sm mr-5 bg-white cursor-pointer hover:text-gray-900"
            onClick={handleBack}
            title="Back"
          />
          <div className="w-full ">
            <h1
              className=" text-gray-900 w-full font-semibold text-base"
              title={roomName}
            >
              {roomName && roomName.length > 15
                ? `${roomName.slice(0, 15)}..`
                : roomName}
              's Daily Meeting
            </h1>
            <div className="w-full flex justify-start items-center text-gray-500 lg:text-sm xl:text-base">
              <p className="pr-2 border-[1px] border-transparent border-r-gray-400 mr-4">
                {moment(new Date()).format(format).split(" ")[0]}
              </p>
              <p className="flex pr-2 justify-center gap-1 items-center border-[1px] border-transparent border-r-gray-400 mr-4">
                <UsersIcon className="h-5 w-5" /> {mediaReducer.remotePeersId.length + 1} user{mediaReducer.remotePeersId.length === 0 ? "" : "s"}
              </p>

              <p className="flex justify-center items-center text-sm text-red-600">
                {userDetails.userId === mediaReducer.presenter ||
                  mediaReducer.selfPresenter ? (
                  "You are presenting"
                ) : mediaReducer.presenter &&
                  mediaReducer.remotePeers[mediaReducer.presenter] ? (
                  `${mediaReducer.remotePeers[mediaReducer.presenter]
                    .peerUsername
                  } is the presenter`
                ) : (
                  <></>
                )}
              </p>
            </div>
          </div>
        </div>
        {((meetingRoomReducer.screenCollabPeer.peerId === "" &&
          roomDetails.viewMode === roomViewModeConst.collabMode) ||
          (roomDetails.viewMode === roomViewModeConst.lectureMode &&
            constantMethod.checkHigherRole(userDetails.role))) &&
          mediaReducer.remotePeersId.length > roomDetails.perPagePeerNum && (
            <div className=" flex justify-center items-center gap-2 w-[15%] cursor-pointer">
              <ChevronLeftIcon
                className={`${mediaReducer.currentPage === 1
                  ? "bg-gray-300 text-white opacity-70"
                  : "bg-white text-gray-500 hover:text-gray-900"
                  } xl:w-9 xl:h-9 lg:w-7 lg:h-7   p-2 rounded-full shadow-sm cursor-pointer `}
                onClick={previousPage}
                title="Previous"
              />
              <p className="flex justify-center items-center  text-gray-500 lg:text-sm xl:text-base">
                {mediaReducer.currentPage} / {mediaReducer.totalPage}
              </p>
              <ChevronRightIcon
                className={` ${mediaReducer.currentPage === mediaReducer.totalPage
                  ? "bg-gray-300 text-white opacity-70"
                  : "bg-white text-gray-500 hover:text-gray-900"
                  }   xl:w-9 xl:h-9 lg:w-7 lg:h-7   p-2 rounded-full shadow-sm mr-5  cursor-pointer `}
                onClick={nextPageFun}
                title="Next"
              />
            </div>
          )}

        {roomDetails.viewMode === roomViewModeConst.collabMode &&
          meetingRoomReducer.screenCollabPeer.peerId !== "" && (
            <>
              <div className="w-[20%]">
                <Listbox
                  value={meetingRoomReducer.screenCollabPeer.peerUsername}
                >
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue sm:text-sm">
                      <span className="block truncate">
                        {meetingRoomReducer.screenCollabPeer.peerUsername}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {meetingRoomReducer.screenCollabPeerArray.map(
                          (screenPeer: screenPeerType) => (
                            <Listbox.Option
                              key={screenPeer.peerId}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                  ? "bg-blue-50 text-blue"
                                  : "text-gray-900"
                                }`
                              }
                              value={screenPeer.peerUsername}
                              onClick={() =>
                                screenResumeForCollabPeer(screenPeer)
                              }
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? "font-medium" : "font-normal"
                                      }`}
                                  >
                                    {screenPeer.peerUsername}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          )
                        )}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </>
          )}
        <div className="flex justify-end items-center w-[50%]">
          {chatDetails?.emojiIconMain==="DND" && (
            <p className="rounded-2xl bg-red-600 text-white shadow-sm  p-2 pr-3 pl-3  text-sm flex justify-center items-center mr-2 cursor-pointer" onClick={()=>{
              dndModalControl(true);
            }}>
              <NoSymbolIcon
                className="h-5 w-5 cursor-pointer mr-1"
                aria-hidden="true"
                title="Do Not Disturb"
              />
              <div className="mr-1">DND</div>
              {formatTime(dndTime)}
            </p>
          )}
          <div className="xl:w-[50%] lg:w-[60%] flex justify-between items-center">
            <p className="rounded-2xl  shadow-sm bg-white p-2 pr-3 pl-3 flex justify-center items-center ">
              <div className="h-2 w-2 rounded-full bg-red-600 mr-2"></div>{" "}
              {formatTime(time)}
            </p>
            <div className="relative flex justify-center items-center">
              <ChatBubbleLeftRightIcon
                className="w-9 text-blue p-2 rounded-full shadow-sm mr-5 bg-white cursor-pointer hover:text-blue border-[0.5px] border-blue"
                title="Chats"
              />
              {unSeenCount > 0 && (
                <p className="bg-red-600 text-white rounded-full p-1 absolute right-4 -top-1 w-4 h-4 flex justify-center items-center text-[.6rem]">
                  {unSeenCount}
                </p>
              )}
            </div>
            <UsersIcon
              className={`w-9   border-[.5px] ${isParticipantActive.val
                ? "text-blue  border-blue hover:text-blue"
                : "text-gray-500 border-transparent hover:text-gray-900 transition-all"
                }  p-2 rounded-full shadow-sm mr-5 bg-white cursor-pointer `}
              onClick={toggleParticipantTab}
              title="Participants"
            />



            <p className="relative flex justify-center items-center">
              <BellIcon
                className={`w-9  border-[.5px]  ${isAlertActive.val
                  ? "text-blue  border-blue hover:text-blue"
                  : "text-gray-500 border-transparent hover:text-gray-900 transition-all"
                  }   p-2 rounded-full shadow-sm mr-5 bg-white cursor-pointer `}
                onClick={toggleAlertsTab}
                title="Alerts"
              />
              {
                alerts.notificationCounter > 0 && !isAlertActive.val &&
                <p className=" bg-red-600 text-white rounded-full p-1 absolute right-4 -top-1 w-4 h-4 flex justify-center items-center text-[.6rem]">
                  {alerts.notificationCounter}
                </p>
              }
            </p>

            {isSidebarOpen ? (
              <ChevronDoubleLeftIcon
                className={`w-9 border-[.5px] text-blue border-blue hover:text-blue p-2 rounded-full shadow-sm mr-5 bg-white cursor-pointer`}
                onClick={toggleSidebar}
                title="Close Sidebar"
              />
            ) : (
              <ChevronDoubleRightIcon
                className={`w-9 border-[.5px] text-gray-500 border-transparent hover:text-gray-900 transition-all p-2 rounded-full shadow-sm mr-5 bg-white cursor-pointer`}
                onClick={toggleSidebar}
                title="Open Sidebar"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
