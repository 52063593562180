
export function generateCustomProtocolURL (
    hostname: string | any,
    path: string| any,
    iv: string | any,
    digest: string | any,
    payload: string | any,
    custCode: string | any
  ) {
    return  `electron-fiddle://${hostname}/${path}/${iv}/${digest}/${payload}/${custCode}`
  }
  

export function callCustomProtocol (URL: string) {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';  // Keep it hidden
    iframe.src = URL;
    document.body.appendChild(iframe);
    // Clean up after triggering
    setTimeout(() => document.body.removeChild(iframe), 1000);
}