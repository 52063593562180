import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../utils/Token";
import { authRequest } from ".";

const getConfig = () => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: getToken(),
  },
});

export const saveLayoutSettings = createAsyncThunk(
  "layoutSettings/saveLayoutSettings",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await authRequest.post("v1/api/layoutsettings/save", data, getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { error: "Failed to save layout settings" });
    }
  }
);

export const getLayoutSettings = createAsyncThunk(
  "layoutSettings/getLayoutSettings",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await authRequest.post("v1/api/layoutsettings/get", data, getConfig());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { error: "Failed to fetch layout settings" });
    }
  }
);
