import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DesktopApp } from "../../interfaces/slicesInterface/desktopApp";
import { RootState } from "../../app/store";
import SocketService from "../../services/socket";
import { socketConnectiontype } from "../../utils/constant";

  
const initialState: DesktopApp = {
    screenNumObj: {}
}

export const disconnectSocketDesktop = createAsyncThunk(
    'desktopApp/disconnectSocket',
    async () => {
        const socketInstanceDesktopApp = new SocketService(
            socketConnectiontype.desktopApp
        ).getInstance(socketConnectiontype.desktopApp);
        if(socketInstanceDesktopApp) {
            socketInstanceDesktopApp.closeSocketConnection();
        }
    }
);

const desktopAppSlice = createSlice({
    name: 'desktopApp',
    initialState,
    reducers: {
        setScreenNumObj: (state, action) => {
            state.screenNumObj = action.payload
        },
    }
})


export const desktopAppSelector = (state: RootState) => state.desktopApp;
export const { setScreenNumObj } = desktopAppSlice.actions;
export default desktopAppSlice.reducer;