
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import Input from '../Input';
import { loginSelector,setComplexPassword } from '../../features/login/loginSlice'
import { loginUser ,fetchSaml} from '../../services/login'
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.png'
import { LoginCardProps } from '../../interfaces/componentsInterface/login';
import { useForm, SubmitHandler, FieldError } from 'react-hook-form';
import { LoginCardInputs } from '../../interfaces/componentsInterface/login';
import Loader from '../Loader';
import OktaImage from '../../assets/images/okta.png'
import MicrosoftImage from '../../assets/images/microsoft.png'
import toast from 'react-hot-toast';





export default function LoginCard(props: LoginCardProps): ReturnType<React.FC> {
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<LoginCardInputs>();
    const dispatch = useAppDispatch();
    const loginState = useAppSelector(loginSelector);
    const navigate = useNavigate();
    const [fromSSO, setFromSSO] = useState<boolean | FieldError | undefined>(false)

    const handleForgotClick = () => {
        props.setForgotPassword(true);
    };


    function checkpassword(password: any) {
        let strength = 0;
        if (password.match(/[a-z]+/)) {
          strength = strength + 1;
        }
        if (password.match(/[A-Z]+/)) {
          strength = strength + 1;
        }
        if (password.match(/[0-9]+/)) {
          strength = strength + 1;
        }
        if (password.match(/[$@#&!]+/)) {
          strength = strength + 1;
        }
        if (password.length >= 8) {
          strength = strength + 1;
        }
    
        if (strength * 20 >= 100) {
          return true;
        }
        return false;
      }

    const handleSaml = async (e:any, type:string) => {
        // e.preventDefault();
        setFromSSO(true);
        const custCode = getValues("custCode")

        if(custCode) {
            const body = {
                custCode: custCode,
                saml: type
            }
    
            const samlResponse = await fetchSaml(body)
    
            if(samlResponse.success) {
                console.log("redirect to saml")
                window.location.href = `${process.env.REACT_APP_AUTH_URL}/v1/api/saml/${samlResponse.saml}`
            } else {
                toast.error(samlResponse.message)
            }
        }
    }


    const onSubmit: SubmitHandler<LoginCardInputs> = (data) => {
        console.log(data);

        if (data.custCode !== "" && data.password !== "" && data.username !== "") {
            let isComplexPassword = checkpassword(data.password);
            dispatch(setComplexPassword(isComplexPassword))
            props.setUsername(data.username);
            props.setCustCode(data.custCode);
            dispatch(loginUser(data));
        }
    };

    useEffect(() => {
        if (loginState && loginState.isLoggedIn) {
            navigate("/dashboard");
        }
    }, [loginState, navigate]);

    // console.log(watch())
    return (
        <>
            {
                // loginState && loginState.loading ? <Loader /> :

                <div className="xl:w-[27%] lg:w-[30%] ml-auto mr-[10%] p-7 xl:p-10 gap-2.5 bg-white rounded-xl relative">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                            className="mx-auto h-16 w-auto"
                            src={Logo}
                            alt="Your Company"
                        />
                        <h2 className="mt-2 font-sans text-center lg:text-2xl xl:text-3xl font-semibold leading-9 tracking-tight text-gray-900 xl:mb-4">
                            Log in to your account
                        </h2>
                        <p className="font-sans text-gray-500 text-center lg:text-sm lg:mt-1 xl:text-[1rem] leading-normal text-base  not-italic font-normal self-stretch ">Welcome back! Please enter your details.</p>
                    </div>

                    <div className="mt-5 sm:mx-auto  w-full">
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' className="lg:space-y-1.5 xl:space-y-4">
                            <div>
                                <Input {...register('custCode', { required: true })} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setValue('custCode', e.target.value, { shouldValidate: true });
                                }} label='Customer Code' type='text' placeholder='Enter your Customer Code' error={errors.custCode} message={errors.custCode && "Customer Code is Required*"} />
                            </div>
                            <div>
                                <Input {...register('username', { required: true })} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setValue('username', e.target.value, { shouldValidate: true });
                                }} label='Username' type='text' placeholder='Enter your username' error={errors.username && !fromSSO} message={errors.username && !fromSSO && "Username is Required*"} />
                            </div>

                            <div>
                                <Input {...register('password', { required: true })} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setValue('password', e.target.value, { shouldValidate: true });
                                }} label='Password' type='password' placeholder='Enter your password' error={errors.password && !fromSSO} message={errors.password && !fromSSO && "Password is Required*"} />
                            </div>

                            <div className=' flex justify-around items-center gap-3' >
                                <button
                                    type='submit'
                                    className="flex w-full justify-center items-center gap-1 rounded-md bg-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue hover:opacity-[0.9] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                                >
                                    {loginState && loginState.loading && (
                                        <div
                                            className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                                            role="status"
                                        >
                                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                Loading...
                                            </span>
                                        </div>
                                    )}
                                    Sign In</button>



                            </div>
                            <p className=' text-center text-gray-500 text-xs'>or signin with</p>
                            <div className=' w-full flex justify-center items-center gap-2'>
                                <button
                                    type="button"
                                    onClick={(e)=> handleSaml(e, "okta")}
                                    className="flex justify-center items-center w-full gap-1 rounded-md bg-white border-[.5px] border-blue px-3 py-1 text-sm text-blue shadow-sm  hover:opacity-[0.9] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                >Login With <img src={OktaImage} className=' h-3' alt='okta' /></button>
                                <button
                                    type="button"
                                    className="flex justify-center items-center w-full gap-1 rounded-md bg-white border-[.5px] border-blue px-3 py-1 text-sm text-blue shadow-sm  hover:opacity-[0.9] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                    onClick={(e)=> handleSaml(e, "microsoft")}
                                ><img src={MicrosoftImage} className=' h-5' alt='okta' />Microsoft</button>
                            </div>

                        </form>

                        <p className="xl:mt-10 lg:mt-5 text-right xl:text-sm lg:text-[0.75rem] font-bold cursor-pointer  text-blue" onClick={handleForgotClick}>
                            Forgot Password?
                        </p>
                    </div>
                    <p className=" absolute lg:text-sm xl:text-base bottom-1 text-gray-700 bg-white  rounded-md opacity-80 flex justify-center items-center gap-1 "><img
                        className="mx-auto h-4 w-auto grayscale"
                        src={Logo}
                        alt="Your Company"
                    />Powered by<a className=' text-gray-900 hover:text-gray-900' href="https://collaborationroom.ai" target="_blank" rel="noreferrer"><b>Collaborationroom.ai</b></a></p>
                </div>

            }
        </>
    );
}
