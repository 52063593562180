import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Device } from "../../interfaces/slicesInterface/device";
import { RootState } from "../../app/store";

const initialState:Device = {
    videoDevices:[],
    audioDevices:[],
    activeAudioDevice:"",
    activeAudioDeviceName:"",
    activeVideoDevice:"",
    activeVideoDeviceName:""

}




const deviceSlice = createSlice({
    name:'device',
    initialState,
    reducers:{
        setAudioDevices: (state,action:PayloadAction<Array<any>>) =>{
            state.audioDevices = action.payload
            
        },

        setVideoDevices: (state,action:PayloadAction<Array<any>>) =>{
            state.videoDevices = action.payload;
        },


        setAudioDevice: (state,action:PayloadAction<string>) =>{
            state.activeAudioDevice = action.payload
        },

        setAudioDeviceName: (state,action:PayloadAction<string>) =>{
            state.activeAudioDeviceName = action.payload
        },

        setVideoDevice: (state,action:PayloadAction<string>) =>{
            state.activeVideoDevice = action.payload
        },
        
        setVideoDeviceName: (state,action:PayloadAction<string>) =>{
            state.activeVideoDeviceName = action.payload
        }
    }
})


export const deviceSelector = (state:RootState) => state.device;
export const {setAudioDevice, setAudioDevices,setAudioDeviceName,  setVideoDevice,setVideoDeviceName, setVideoDevices} = deviceSlice.actions;
export default deviceSlice.reducer;