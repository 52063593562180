import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { reconnectionStatusInterface } from "../../interfaces/slicesInterface/reconnectionStatus";

const initialState: reconnectionStatusInterface = {
  reconnectionStatus: false,
  reconnectionStatusInRoompage: false,
  isReconnectionStatusVideo: false,
  isReconnectionStatusAudio: false,
  isReconnectionStatusScreenOne: false,
  isReconnectionStatusScreenTwo: false,
  isReconnectionStatusScreenForElectron: false,
  checkMediaSocketForDesktopApp: false
};

const reconnectionStausSlice = createSlice({
  name: "reconnectionstatus",
  initialState,
  reducers: {
    setReconnectionStatusInRoompage: (state, action: PayloadAction<any>) => {
      state.reconnectionStatusInRoompage = action.payload;
    },
    setIsReconnectionStatusVideo: (state, action: PayloadAction<any>) => {
      state.isReconnectionStatusVideo = action.payload;
    },
    setIsReconnectionStatusAudio: (state, action: PayloadAction<any>) => {
      state.isReconnectionStatusAudio = action.payload;
    },
    setIsReconnectionStatusScreenOne: (state, action: PayloadAction<any>) => {
      state.isReconnectionStatusScreenOne = action.payload;
    },
    setIsReconnectionStatusScreenTwo: (state, action: PayloadAction<any>) => {
      state.isReconnectionStatusScreenTwo = action.payload;
    },
    setcheckMediaSocketForDesktopApp: (state, action: PayloadAction<any>) => {
      state.checkMediaSocketForDesktopApp = action.payload;
    },
    setIsReconnectionStatusScreenForElectron: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isReconnectionStatusScreenForElectron = action.payload;
    },
    setReconnectionStatus: (state, action: PayloadAction<any>) => {
      state.reconnectionStatus = action.payload;
    },
    clearReconnectionStatus: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {},
});

export const reconnectionStatusSelector = (state: RootState) =>
  state.reconnectionStatus;
export const {
  setReconnectionStatusInRoompage,
  setIsReconnectionStatusVideo,
  setIsReconnectionStatusAudio,
  setIsReconnectionStatusScreenForElectron,
  setReconnectionStatus,
  clearReconnectionStatus,
  setIsReconnectionStatusScreenOne,
  setIsReconnectionStatusScreenTwo,
  setcheckMediaSocketForDesktopApp
} = reconnectionStausSlice.actions;
export default reconnectionStausSlice.reducer;
