import React, { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { DeviceListProps } from '../../interfaces/componentsInterface/navDevices'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { saveLayoutSettings } from '../../services/layoutSettings'
import { deviceSelector } from '../../features/device/deviceSlice'



function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

export default function DeviceList({ Icon, list, setCurrentDevice, activeDevice }: DeviceListProps): ReturnType<React.FC> {
    const [selected, setSelected] = useState<MediaDeviceInfo>();
    const devices = useAppSelector(deviceSelector);
    const dispatch = useAppDispatch();

    const handleDeviceChange = (value: MediaDeviceInfo) => {
        setSelected(value);
        dispatch(setCurrentDevice(value.deviceId));

        dispatch(saveLayoutSettings({
            type: "dashboard",
            data: {
                mic: {
                    deviceName: value.kind === 'audioinput' ? value.label : devices?.activeAudioDeviceName || "",
                    deviceId: value.kind === 'audioinput' ? value.deviceId : devices?.activeAudioDevice || ""
                },
                camera: {
                    deviceName: value.kind === 'videoinput' ? value.label : devices?.activeVideoDeviceName || "",
                    deviceId: value.kind === 'videoinput' ? value.deviceId : devices?.activeVideoDevice || ""
                }
            }
        }));
    };
   

    useEffect(() => {
        if (list?.length) {
            setSelected(list.find((item) => item.deviceId === activeDevice) || list[0]);
        }
    }, [activeDevice, list]);
    return (
        <div className='lg:w-[15%] xl:w-[20%]  '>

            <Listbox value={selected} onChange={handleDeviceChange} >
                {({ open }) => (
                    <>

                        <div className="relative">
                            <Listbox.Button className="relative w-full  rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm  ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 hover:bg-gray-100 border-gray-50  border cursor-pointer">
                                <div className="flex items-center justify-between">
                                    <Icon className="h-5 text-gray-500 w-1/5" />
                                    <p className="ml-3 truncate w-4/5" title={`${selected?.label?.toLowerCase().trim().includes("default") ? selected?.label.split('-')[1] : selected?.label}`}>{selected?.label?.toLowerCase().trim().includes("default") ? selected?.label.split('-')[1] : selected?.label}</p>
                                </div>
                                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {list && list.length > 0 && list.map((device: MediaDeviceInfo) => (
                                        <Listbox.Option
                                            key={device?.deviceId}
                                            className={({ selected, active }) =>
                                                classNames(
                                                    active ? 'shadow-md' : 'text-gray-900',
                                                    selected && 'border border-blue ',
                                                    'relative rounded-md select-none py-2 pl-3 pr-9 cursor-pointer'
                                                )
                                            }
                                            value={device}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <div className="flex items-center">

                                                        <span
                                                            title={`${device?.label?.toLowerCase().trim().includes("default") ? device?.label.split('-')[1] : device?.label}`}
                                                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                        >
                                                            {device?.label?.toLowerCase().trim().includes("default") ? device?.label.split('-')[1] : device?.label}
                                                        </span>
                                                    </div>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    )
}
