import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { ControlActionModalProps } from "../../interfaces/componentsInterface/controlActionModal";
import Button from "../Button";
import { useAppSelector } from "../../app/hooks";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { BRB_TIME_LIMIT_MINUTES, BREAK_TIME_LIMIT_MINUTES, emojiEventConst, LUNCH_TIME_LIMIT_MINUTES, socketConnectiontype } from "../../utils/constant";
import SocketService from "../../services/socket";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { timeToMinutes, getFormattedMinutes } from "../../utils/datetimeformat";
import { playBeepForLunchBrbExtend } from "../../utils/AudioVideoDevices";

export default function ControlActionModal(props: ControlActionModalProps): ReturnType<React.FC> {

  const [time, setTime] = useState("0 Min");
  const [breakLimitCrossed, setBreakLimitCrossed] = useState(false);
  const [lunchLimitCrossed, setLunchLimitCrossed] = useState(false);
  const [brbLimitCrossed, setBrbLimitCrossed] = useState(false);
  const [lunchTimeLimit, setLunchTimeLimit] = useState(0);
  const [breakTimeLimit, setBreakTimeLimit] = useState(0);
  const [brbTimeLimit, setBrbLimit] = useState(0);
  const userState = useAppSelector(userDetailsSelector);


  const environments = useAppSelector(environmentSelector)?.environments;

const lunchTimeLimitStatus = environments?.LUNCH_TIME_LIMIT_MINUTES?.status ?? LUNCH_TIME_LIMIT_MINUTES.status;


  let messageSubscriberChat: any;

  const socketInstanceChat = new SocketService(
    socketConnectiontype.chat
  ).getInstance(socketConnectiontype.chat);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    messageSubscriberChat = socketInstanceChat
      .getMessages()
      .subscribe((message: string) => {
        processMessage(JSON.parse(message));
      });
    return () => {
      messageSubscriberChat.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processMessage = async (message: any) => {

    switch (message.type) {
      case "EMOJI_FOR_BREAKANDLUNCH":
        if (userState.userId === message.peerUserId) {
          //@ts-ignore
          setTime(getFormattedMinutes(message.time, 2, ' Hour '));
        }
        break;

      case "EMOJI_FOR_BRB":
        if (userState.userId === message.peerUserId) {
          //@ts-ignore
          setTime(getFormattedMinutes(message.time, 2, ' Hour '));
        }
        break;

      case "REMINDER_TIME_LIMIT_EXCEEDED":
        if (message.action && message.action === emojiEventConst.BRB && message.peerUserId === userState.userId) {
          setBrbLimit(message.limit)
          setBrbLimitCrossed(true);
          playBeepForLunchBrbExtend();
        }
        if (message.action && message.action === emojiEventConst.BREAK && message.peerUserId === userState.userId) {
          setBreakTimeLimit(message.limit)
          setBreakLimitCrossed(true);
          playBeepForLunchBrbExtend();
        }
        if (message.action && message.action === emojiEventConst.LUNCH && message.peerUserId === userState.userId) {
          setLunchTimeLimit(message.limit)
          setLunchLimitCrossed(true);
          playBeepForLunchBrbExtend();
        }
        break;

      default:
        break;
    }
  }

  const handleModalClose = () => {
    if (props.selectedEvent && (props.selectedEvent === emojiEventConst.LUNCH || props.selectedEvent === emojiEventConst.BREAK)) {
      setTime("0 Min");
      setLunchLimitCrossed(false);
      setBreakLimitCrossed(false);
    }

    if (props.selectedEvent && props.selectedEvent === emojiEventConst.BRB) {
      setTime("0 Min");
      setBrbLimitCrossed(false);
    }
  };



  return (

    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden backdrop-blur-sm">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-[40%] sm:max-w-[50%] max-h-[70vh] overflow-y-scroll scrollbar sm:p-6">
                  <p
                    className=" absolute right-0 top-2 text-md text-gray-900 cursor-pointer"
                    title="Close"
                    onClick={() => {
                      props.closeControlActionModal?.();
                      handleModalClose();
                    }}
                  >
                    <XMarkIcon className=" h-5 w-5" />
                  </p>
                  <Dialog.Title
                    as="h3"
                    className="xl:text-lg lg:text-base text-center font-bold leading-6 text-gray-900"
                  >
                    {props.selectedEvent === emojiEventConst.BRB ? (
                      <>
                        Be right back
                        {brbLimitCrossed && <div className="text-red-600">Your Time limit for Be Right Back is exceeded. It is set to {brbTimeLimit} minute</div>}
                      </>
                    ) : null}

                    {props.selectedEvent === emojiEventConst.LUNCH ? (
                      <>
                        On lunch
                        {lunchLimitCrossed && <div className="text-red-600">Your Time limit for Lunch is exceeded. It is set to {lunchTimeLimit} minute</div>}
                      </>
                    ) : null}

                    {props.selectedEvent === emojiEventConst.BREAK ? (
                      <>
                        On break
                        {breakLimitCrossed && <div className="text-red-600">Your Time limit for Break is exceeded. It is set to {breakTimeLimit} minute</div>}
                      </>
                    ) : null}
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col justify-center items-center gap-2">
                    <p className=" text-5xl text-blue border-[.5px] border-blue px-3 py-1 rounded-md" >{time}</p>
                    <Button children="Go Back" type="primary" className="py-[1px]" onClick={() => {
                      props.closeControlActionModal?.();
                      handleModalClose();
                    }} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>



    </>
  )
}