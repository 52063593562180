import Image from "../../assets/images/404.jpg";
import styles from "./styles.module.css";
import SkeletonLoading from "../SkeletonLoading";
import { FeedsProps } from "../../interfaces/componentsInterface/feeds";
import { useAppSelector } from "../../app/hooks";
import { activityChannelSelector } from "../../features/activityChannel/activityChannelSlice";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { capitalizeWords, timeZoneFormat } from "../../utils/datetimeformat";
import moment from "moment";
import { getInitials } from "../../utils/initials";
import { Role } from "../../interfaces/slicesInterface/activityChannel";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { useState } from "react";
import { peerVolumeSelector } from "../../features/peerVolume/peerVolumeSlice";
import { generateThumbnailsUrl } from "../../utils/generateImageURL";
import { getVolumeClasses } from "../../utils/audio";
import { PencilIcon, TagIcon } from "@heroicons/react/20/solid";

export default function Feeds(props: FeedsProps): ReturnType<React.FC> {
    const { handleEditPost, handleScrollState, filteredFeeds, selectedFeedTopic } = props;

    const { subscribedTopics, loading } = useAppSelector(activityChannelSelector);
    const user = useAppSelector(userDetailsSelector);
    const peerVolume = useAppSelector(peerVolumeSelector);
    const environments = useAppSelector(environmentSelector);
    const [imageError, setImageError] = useState(false);
    const { format } = timeZoneFormat();
  
    const spacesConfig = environments?.environments?.REACT_APP_SPACES_CONFIG?.data?.[0] ?? '';








    // function formatDateString(isoString: string): string {
    //     const date = moment(isoString);

    //     // Format the date and time according to the desired format
    //     const formattedDate = date.format("hh:mmA, DD.MM");

    //     return formattedDate;
    // }

    // const truncateName = (attachmentName: string, maxLength: number = 10) => {
    //     // const maxLength = 25;
    //     if (attachmentName.length > maxLength) {
    //         return (
    //             <span title={attachmentName}>{attachmentName.substring(0, maxLength) + "..."}</span>
    //         );
    //     }
    //     return <span title={attachmentName}>{attachmentName}</span>;
    // };

    return (
        <>
            <div
                onScroll={(e: any) => {
                    handleScrollState(e);
                }}
                className={`w-full ${styles.height}  scrollbar overflow-y-scroll overflow-x-hidden flex flex-col justify-start items-center p-2`}
            >
                {loading && filteredFeeds.length < 0 ? (
                    <SkeletonLoading component="Feeds" />
                ) : (
                    <>
                    {filteredFeeds?.length > 0 ?                     <>
                        {filteredFeeds?.map((post: any) =>
                            subscribedTopics
                                .filter((tpc: any) => tpc._id === post.topicId)
                                .map((topic: any) => {
                                    return (
                                        topic.permissions &&
                                        (topic.permissions[user.role as Role].includes("read") ||
                                            selectedFeedTopic === "myPosts") && (
                                            <div
                                                key={topic._id}
                                                className=" flex flex-col relative justify-start items-center w-full gap-3 p-2 border-[0.5px] border-gray-100 rounded-xl my-2"
                                            >
                                                <div className=" flex justify-between items-start w-full">
                                                    <div className=" flex justify-center items-start gap-2">
                                                        <div className="inline-flex  h-9 w-9 xl:h-10 xl:w-10  items-center justify-center rounded-full bg-blue">
                                                        {post.imageUrl && imageError ? 
                                                            <img
                                                              className={`inline-block h-9 w-9 xl:h-10 xl:w-10 rounded-full ring-2 ring-white ${peerVolume && getVolumeClasses(peerVolume[post.userId])}`}
                                                              src={post.imageUrl}
                                                              alt="User"
                                                            
                                                            /> : post.imageUrl && !imageError ?  
                                                            <img
                                                              className={`inline-block h-9 w-9 xl:h-10 xl:w-10 rounded-full ring-2 ring-white  ${peerVolume && getVolumeClasses(peerVolume[post.userId])}`}
                                                              src={generateThumbnailsUrl(post?.imageUrl?.split('/').pop(), 240, user.custId, post.userId, spacesConfig)}
                                                              alt="User"
                                                              onError={() => setImageError(true)}
                                                            /> : 
                                                            <span className="text-[0.65rem] xl:text-sm font-medium leading-none text-white">
                                                                {post.firstName &&
                                                                    getInitials(
                                                                        `${
                                                                            post.firstName +
                                                                            " " +
                                                                            post.lastName
                                                                        }`
                                                                    )}
                                                            </span> 
                                                        }
                                                            
                                                        </div>
                                                        <div className=" flex flex-col justify-start items-start w-[80%]">
                                                            <h3 className=" text-gray-900 font-semibold lg:text-sm xl:text-base font-sans sm:text-xl break-all w-[70%] ">
                                                                {/* {truncateName(
                                                                    capitalizeWords(post.title),
                                                                    13
                                                                )} */}
                                                                {capitalizeWords(post.title)}
                                                            </h3>
                                                            <div className=" items-center gap-1 xl:gap-2 w-full">
                                                                <p className="text-[0.5rem] xl:text-[.65rem]  w-full ">
                                                                    <span className=" text-blue">
                                                                        Author:
                                                                    </span>{" "}
                                                                    {/* {truncateName(
                                                                        capitalizeWords(
                                                                            `${
                                                                                post.firstName +
                                                                                " " +
                                                                                post.lastName
                                                                            }`
                                                                        ),
                                                                        5
                                                                    )} */}
                                                                    <span className="break-all w-full ">
                                                                        {capitalizeWords(
                                                                        `${`${post.firstName+" "+post.lastName}`}`
                                                                        )}
                                                                    </span>
                                                                </p>
                                                                <p className=" text-[0.5rem] xl:text-[.65rem] w-full ">
                                                                    <span className=" text-blue">
                                                                        Topic:
                                                                    </span>{" "}
                                                                    {/* {truncateName(topic.topic, 5)} */}
                                                                    <span className="break-all w-full">
                                                                        {topic.topic}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <p className=" text-[0.6rem] xl:text-[.7rem] text-gray-500">
                                                                {/* 13:32PM, 26.11 */}
                                                                {moment(new Date(post.createdAt)).format(format)}
                                                            </p>
                                                            {post.edited && <p className=" text-[0.6rem] xl:text-[.7rem] text-gray-500">
                                                                {post.editorRole === "Admin" ? "[Last Edited By Admin]" : `[Last Edited By ${`${post.firstName+" "+post.lastName}`}]`}
                                                            </p>}
                                                        </div>
                                                    </div>
                                                    <div className=" flex justify-center  absolute top-1 right-1 items-center gap-2">
                                                        {/* {topic.permissions &&
                                                            topic.permissions[
                                                                user.role as Role
                                                            ].includes("write") && (
                                                                <PencilIcon
                                                                    onClick={() => {
                                                                        handleEditPost(
                                                                            topic.topic,
                                                                            post.topicId,
                                                                            post.title,
                                                                            post.description,
                                                                            post._id,
                                                                            post.type
                                                                                ? post.type
                                                                                : "normal"
                                                                        );
                                                                    }}
                                                                    className=" h-4 w-4 xl:h-5 xl:w-5 text-blue cursor-pointer"
                                                                    title="Edit Post"
                                                                />
                                                            )} */}
                                                        {(user.userId === post.userId) && (
                                                                <PencilIcon
                                                                    onClick={() => {
                                                                        handleEditPost(
                                                                            topic.topic,
                                                                            post.topicId,
                                                                            post.title,
                                                                            post.description,
                                                                            post._id,
                                                                            post.type || "normal"
                                                                        );
                                                                    }}
                                                                    className="h-4 w-4 xl:h-5 xl:w-5 text-blue cursor-pointer"
                                                                    title="Edit Post"
                                                                />
                                                            )}

                                                        <TagIcon
                                                            title={capitalizeWords(post.type)}
                                                            className={`h-4 w-4 xl:h-5 xl:w-5 ${
                                                                post?.type === "urgent"
                                                                    ? " text-red-600"
                                                                    : "text-blue"
                                                            }`}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full">
                                                    <p
                                                        className="text-xs xl:text-sm  post-body break-all"
                                                        id={post._id}
                                                        dangerouslySetInnerHTML={{
                                                            __html: post.description,
                                                        }}
                                                    />
                                                    {/* <img src={Image} alt="ima" className='' /> */}
                                                </div>
                                            </div>
                                        )
                                    );
                                })
                        )}

                        {subscribedTopics.length < 1 && (
                            <p className="flex flex-col justify-start items-center text-sm w-full gap-3 p-2 border-[0.5px] border-gray-100 rounded-xl my-2">
                                Subscribe to topics to get feeds.
                            </p>
                        )}
                    </> : "No feeds yet"}
                    </>
                )}
            </div>
        </>
    );
}
